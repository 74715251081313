import { useState, useEffect } from "react";
import React from "react";
import { Button, Col, NavLink, Row, Spinner } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import patientService from "./patient.service";
import { useFormik } from "formik";
import { object, string } from "yup";
import PatientList from "./PatientList";
import { IsoToUkDate, HandleValueLength, ScrollToBottom, scrollToElementId, ScrollToTop, IsoDateToHhMmTime } from "../../_shared/shared.functions";
import { Patient, PatientSearchType } from "./patient.models";
import { ValidNhsNumber } from "../../_shared/shared.validation";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import { PatientSearchAnalyticsPrimaryCategory, PatientPageTitles, PatientSearchNhsNumberAnayticsPageName } from "./patient.enums";
import { Category } from "../analytics/analytics.models";
import useAnalytics from "../analytics/hooks/useAnalytics";
import { ErrorSummary } from "nhsuk-react-components";

export default function PatientSearchNhsNumber() {
    useAnalytics({ primaryCategory: PatientSearchAnalyticsPrimaryCategory.PrimaryCategory, subCategory1: PatientSearchNhsNumberAnayticsPageName.SubCategory1 } as Category);
    useDocumentTitle(PatientPageTitles.NhsNumberPatientSearch);
    
    const location = useLocation();
    const navigate = useNavigate();

    const [vaccinationRedirect, setVaccinationRedirect] = useState(location.state?.[0]);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [patient, setPatient] = useState(null);
    const [searchValues, setSearchValues] = useState('');
    const [patientAdd, setPatientAdd] = useState(location?.state?.[0] as Patient || null);
    const [hoveredField, setHoveredField] = useState<string | null>(null);
    const patientSearchType: PatientSearchType = {
        NhsNumberSearch: true,
        PdsSearch: false,
        RavsSearch: false
    };

    useEffect(() => {
        const processResponse = async (response) => {
            if (response !== null) {
                let p = response?.PdsPatient;
                if (p !== null) {
                    let id = response?.RavsPatient?.PatientId;
                    if ((response?.RavsPatient !== null && !id) || response?.RavsPatient === null) {
                        const res = await patientService.add$(p);
                        p.PatientId = res.PatientId;
                        setPatient(p);
                    } else {
                        p.PatientId = id;
                        setPatient(p);
                    }
                } else {
                    setPatient(null);
                }
            } else {
                setPatient(null);
            }
        };

        if (response) {
            processResponse(response);
        }
    }, [response]);

    const closeNotification = () => {
        setPatientAdd(null);
    };

    const resetVaccinationRedirect = () => {
      setVaccinationRedirect(null);
      navigate(`/patient/search/nhs-number`);
    };

    const viewRecord = () => {
        navigate(-1); // Navigate back one page
    };

    const formik = useFormik({
        initialValues: {
            NhsNumber: ''
        },
        validationSchema: object().shape({
            NhsNumber: string()
                .min(10, 'Enter 10 digits')
                .max(10, 'Enter 10 digits')
                .test('isValidNhsNumber', 'Enter a correct NHS number', function (value) {
                    return !value || ValidNhsNumber(value);
                })
                .required("Enter an NHS number")
        }),
        enableReinitialize: true,
        validateOnChange:false,
        
        onSubmit: async (values) => {
            setLoading(true);
            setSearchValues(values.NhsNumber);
            try {
                const response = await patientService.nhsNumberSearch$(values.NhsNumber);
                setResponse(response);
                ScrollToBottom();
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <>
            <fieldset className="nhsuk-fieldset col">
                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                    {patientAdd && patientAdd?.FirstName && (
                        <div className="col-12">
                            <div className="card mb-4" style={{ border: "2px solid green" }}>
                                <div className="card-header text-white background-success" style={{ fontSize: '24px', position: 'relative' }}>
                                    Patient added
                                    <span style={{ position: 'absolute', top: 10, right: 10, fontSize: '16px' }}>
                                        <u className="text-white cursor-pointer" onClick={closeNotification}>Close</u>
                                    </span>
                                </div>
                                <div className="card-body">
                                    <p className="card-text mb-0">{patientAdd.FirstName} {patientAdd.LastName} with date of birth {IsoToUkDate(patientAdd.DateOfBirth)} has been added to RAVS.</p>
                                    <div className="notification-buttons mt-2">
                                        <u className="text-primary cursor-pointer" style={{ fontSize: '16px' }} onClick={viewRecord}>View Record</u>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {formik.touched.NhsNumber && (Object.keys(formik.errors).length > 0) && (
                        
                        <ErrorSummary id='formErrors'>
                            <ErrorSummary.Title >There is a problem</ErrorSummary.Title>
                            <ErrorSummary.Body>
                                <ErrorSummary.List>
                                    {Object.keys(formik.errors).map((fieldName, index) => (
                                        <ErrorSummary.Item key={index}>
                                            <button
                                                    className={`anchor-style ${hoveredField === fieldName ? "hovered-color" : "error-color"
                                                    }`}
                                                onMouseEnter={() => setHoveredField(fieldName)}
                                                onMouseLeave={() => setHoveredField(null)}
                                                key={index}
                                                onClick={() => scrollToElementId(fieldName)}
                                            >
                                                {formik.errors[fieldName]}
                                            </button>
                                        </ErrorSummary.Item>
                                    ))}
                                </ErrorSummary.List>
                            </ErrorSummary.Body>
                        </ErrorSummary>
                    )}
  
                    { vaccinationRedirect && vaccinationRedirect.RedirectToVaccination &&
                      <div className="card mb-4" style={{ border: "2px solid green" }}>
                        <div className="card-header text-white background-success" style={{ fontSize: '24px', position: 'relative', borderRadius: 0 }}>
                          Record saved
                          <span style={{ position: 'absolute', top: 10, right: 10, fontSize: '16px', cursor: 'pointer' }}>
                            <u className="text-white cursor-pointer" onClick={resetVaccinationRedirect}>Close</u>
                          </span>
                        </div>
                        <div className="card-body">
                          <p className="card-text mb-0">You successfully saved {vaccinationRedirect.Name} record at {IsoDateToHhMmTime(vaccinationRedirect.AuditDateTime)}</p>
                          <u className="text-primary cursor-pointer mt-0" style={{ fontSize: '16px', cursor: 'pointer' }} onClick={() => { navigate(`/patient/${vaccinationRedirect.PatientId}`)}}>View record</u>
                        </div>
                      </div>
                    }
                    
                    <h1 className="nhsuk-fieldset__heading">
                        Find a patient
                    </h1>
                </legend>
                <div className="nhsuk-tabs" data-module="nhsuk-tabs">
                    <ul className="nhsuk-tabs__list" aria-label="Patient search by NHS number tab">
                        <li className="nhsuk-tabs__list-item nhsuk-tabs__list-item--selected">
                            <NavLink href="#" active>By NHS number</NavLink>
                        </li>
                        <li className="nhsuk-tabs__list-item">
                            <NavLink tag={Link} to="/patient/search/pds">By demographics</NavLink>
                        </li>
                        <li className="nhsuk-tabs__list-item">
                            <NavLink tag={Link} to="/patient/search/records">By local records</NavLink>
                        </li>
                    </ul>
                    <div className="nhsuk-tabs__panel pt-0" id="by-nhs-number">
                        <br />
                       
                        <form onSubmit={formik.handleSubmit}>
                           <div className={formik.touched.NhsNumber && formik.errors.NhsNumber ? "nhsuk-form-group nhsuk-form-group--error" : "nhsuk-form-group"}>
                            <h2 className="nhsuk-table__caption">Search by NHS number</h2>
                            <h3 className="nhsuk-label">The quickest way to find a patient.</h3>
                                <div className="nhsuk-hint" id="select-vaccines-hint">
                                    Enter a 10 digit NHS number
                                </div>
                                {formik.touched.NhsNumber && formik.errors.NhsNumber && (
                                    <span className="nhsuk-error-message" id="VaccineProgramIdError">
                                        <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.NhsNumber}
                                    </span>
                                )}
                                <input
                                    value={formik.values.NhsNumber}
                                    className="nhsuk-input nhsuk-input--width-10"
                                    id="NhsNumber"
                                    name="NhsNumber"
                                    type="number"
                                    aria-label="Enter a 10 digit NHS number"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        HandleValueLength(e, formik, 10);
                                    }}
                                />
                            </div>
                            <Row>
                                <Col className="pt-2">
                                    {loading ? (
                                        <Spinner></Spinner>
                                    ) : (
                                        <Button
                                            type="submit"
                                            className="nhsuk-button"
                                            data-module="nhsuk-button"
                                            disabled={loading}
                                            onClick={() => {if (Object.keys(formik.errors).length > 0) {scrollToElementId('formErrors')}}}
                                        >
                                            Search
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </fieldset>
            {!loading && <PatientList patient={patient} patientSearchType={patientSearchType} searchValues={searchValues}></PatientList>}
        </>
    );
}
